<template>
  <div class="ui grid container-2" id="tycshapp">
    <div class="two column row stackable">
      <div class="column div-1">
        <div class="left">
          <h1>All-in-one <span>Mobile App!</span></h1>

          <p>Turning your phone into a digital wallet, TayoCash lets you send money, pay bills, shop, and many more.</p>
        </div>
      </div>
      <img class="mid-phone" src="@/assets/phone-div2.png" alt="Tayocash">

      <div class="column div-2">
        <div class="right">
          <h1><span>Quick, Simple, </span>Secure.</h1>

          <p>Enjoy reliable access to a seamless and contactless mobile payment solution anytime, anywhere.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content2'
}
</script>

<style scoped lang='scss'>
</style>
